// import { SMTPClient } from 'smtp-client';

// let s = new SMTPClient({
//   host: 'smtp-relay.gmail.com',
//   port: 587
// });

// const API_CONTACT_URL = process.env?.REACT_PUBLIC_EMAIL_URL;
import { Item } from "./types";

const API_CONTACT_URL = "https://api.jinomotos.com/api";
// const template = "<html><body><h2>Hi $USER has picka following pices from the warehouse</h2><div>$ITEMS</div><div>$MESSAGE</div></body></html>";
const template = "<html><body><div><h2>Picked from warehouse<h2><br/><h3>Picked by</h3><p>$USER</p></div><div><h3>Message</h3><p>$MESSAGE</p><div><h3>Articles</h3><table><tr><th>Article</th><th>Quantity</th></tr><tr>$ITEMS</tr></table></div></body></html>";

// <html><body><div><h2>Picked from warehouse<h2></div><br/><h3>Articles</h3>$ITEMS</div><div>$MESSAGE</div></body></html>


export const smtpApi = {
  send: async(items: Item[], message: string, to: string, person: string): Promise<void> => {
    const itemsData = items.map(item => `<td>${item.code}</td><td>${Number(item.quantity)}</td>`);
    const codedMessage = template.replace("$USER", person).replace("$MESSAGE", message.replace("\n", "<br/>")).replace("$ITEMS", itemsData.join("</tr><tr>"));
    // let url = `${API_CONTACT_URL}/mail/simpleHtml?to=${to}&f=info@jinoteco.com&n=${person}&s=Project pick from warehouse&m=${message}`;
    let url = `${API_CONTACT_URL}/mail/simpleHtml`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        // Authorization: `Bearer ${API_SECRET}`
      },
      // body: JSON.stringify({ from: "info@jinoteco.com", to: "jimmy.nordh@jinoteco.com", subject: "Project pick from warehouse", message: codedMessage })
      body: JSON.stringify({ from: "info@jinoteco.com", to: to, subject: "Project pick from warehouse", message: codedMessage })
    };
    console.log(`u rL: ${url}`);
    fetch(url, requestOptions)
      .then(d => console.info(`Response from send ${d.status}`))
      .catch(e => console.info(`Submit gave error? ${e}`));
  },
}

// send: async(data: string): Promise<void> => {
//   s.secure();
//   await s.connect();
//   await s.greet({ hostname: 'smtp-relay.gmail.com' }); // runs EHLO command or HELO as a fallback
//   // await s.authPlain({ username: 'john', password: 'secret' }); // authenticates a user
//   await s.mail({ from: 'info@jinoteco.com' }); // runs MAIL FROM command
//   await s.rcpt({ to: 'jimmy@jinoteco.com' }); // runs RCPT TO command (run this multiple times to add more recii)
//   await s.data('Mail test'); // runs DATA command and streams email source
//   await s.quit(); // runs QUIT command
// },
