import { AppBar, Box, Button, IconButton, TextField, Toolbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { Item } from "../lib/types";

export interface FormProps {
  item: Item;
  setCode: (code: string) => void;
  setQuantity: (quantity: string) => void;
  close: () => void;
  saveAndClose: () => void;
}

export default function ArticleForm(props: FormProps) {

  const { item, close, setCode, setQuantity, saveAndClose } = props;
  const qnty = item.quantity;

  return (
    <>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => close()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Add Article with quantity
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ alignSelf: 'center', justifyContent: 'center', width: '80%' }}>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
          Set article # and quantity
        </Typography>
        <h2>Article</h2>
        <form>
          <TextField
            style={{ width: "100%", marginBottom: "1em" }}
            variant="outlined"
            label="Article"
            type="text"
            onFocus={event => {
              event.target.select();
            }}
            value={item.code}
            onChange={(e) => setCode(e.target.value)}
          />
          <h2>Quantity</h2>
          <TextField
            style={{ width: "100%", marginBottom: "1em" }}
            variant="outlined"
            label="Quantity"
            type="text"
            InputProps={{
              inputProps: { min: -99 }
            }}
            focused
            onFocus={event => {
              event.target.select();
            }}
            value={qnty}
            onChange={(e) => setQuantity(e.target.value)}
            onKeyUp={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();
                saveAndClose();
              }
            }}
          />
          <Button
            sx={{ p: '1.1em' }}
            size="medium"
            variant="contained"
            onClick={saveAndClose}
          >
            Save
          </Button>
        </form>
      </Box>
    </>
  )
};
