import React, { useState } from 'react';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { Result } from "@zxing/library";
import { AppBar, Box, Button, Dialog, Grid, IconButton, Slide, TextField, Toolbar, Typography, } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from "@mui/material/transitions";
import { Item } from "./lib/types";
import ArticleTable from "./components/table";
import { smtpApi } from "./lib/sender";
import ArticleForm from "./components/ArticleForm";
import AddIcon from '@mui/icons-material/Add';


type ScanMode = 'BARCODE' | 'DESCRIPTION';

// const targetMail: string = process?.env?.PUBLIC_DEFAULT_EMAIL_TARGET ? process.env.PUBLIC_DEFAULT_EMAIL_TARGET : 'lagerhalmstad@coromatic.se';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const BarcodeScanner = () => {
  const [scannedBarcodes, setScannedBarcodes] = useState<Array<Item>>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<Item | null>(null);
  const [scannerMode, setScannerMode] = useState<ScanMode>('BARCODE');
  const [scannerOn, setScannerOn] = useState<boolean>(true);
  const [currentCode, setCurrentCode] = useState<string>('');
  const [quantity, setQuantity] = useState<string>('1');
  const [message, setMessage] = useState<string>('');
  const [user, setUser] = useState<string>('');
  const [mailRecpt, setMailRecpt] = useState<string>('maria.johansson@coromatic.se');

  console.info(`Set mail ${mailRecpt}`);

  const handelContinue = () => {
    setShowDialog(true);
    setScannerOn(false);
  }

  const handelCancelSend = () => {
    setShowDialog(false);
    setScannerMode('BARCODE')
    setScannerOn(true);
  }

  const handelScanProject = () => {
    if (scannerMode === 'BARCODE') {
      setScannerMode('DESCRIPTION')
      setScannerOn(true);
    } else {
      setScannerMode('BARCODE')
      setScannerOn(false);
    }
  }

  const handelSetQuantity = (quantity: string) => {
    // let q = Number(quantity.trim());
    // if (!isNaN(q)) {
      setQuantity(quantity);
    // } else setQuantity(1);
  }

  const removeItem = (itemCode: string) => {
    const updatedItems = scannedBarcodes && scannedBarcodes.filter((item) => item.code !== itemCode);
    setScannedBarcodes(updatedItems);
  }

  const editItem = (itemCode: string) => {
    const item = scannedBarcodes && scannedBarcodes.filter((item) => item.code === itemCode)[0];
    setCurrentItem(item);
    setCurrentCode(item.code);
    setQuantity(item.quantity);
    setShowModal(true);
  }

  const handleScan = (data: Result) => {
    console.info(`handleScan: (${scannerMode}) => ${data}`)
    if (data) {
      if (scannerMode === 'DESCRIPTION') {
        console.info(`handleScan: DESCRIPTION (${scannerMode}) => ${data}`)
        setMessage(data.getText());
      } else {
        const codeText = data.getText();
        if (!scannedBarcodes.some(item => item.code === codeText)) {
          setCurrentCode(codeText);
          setShowModal(true);
        }
      }
    }
  };

  const handleError = (error: Error) => {
    console.error('Scanning error:', error);
  };


  const scanner = (<QrScanner
    containerStyle={{maxWidth: '600px', alignSelf: 'center'}}
    onResult={handleScan}
    onError={handleError}
    stopDecoding={!scannerOn}
  />);

  const resetForm = () => {
    setScannedBarcodes([]);
    setMessage('');
    resetArticleForm();
  }

  const resetArticleForm = () => {
    setCurrentCode('');
    setQuantity('1'); // Reset quantity for the next scan
  }

  const handleSend = () => {
    // smtpApi.send(scannedBarcodes, message, "jimmy.nordh@jinoteco.com", user);
    smtpApi.send(scannedBarcodes, message, mailRecpt, user);
    setScannerMode('BARCODE');
    setScannerOn(true);
    setShowDialog(false);
    resetForm();
  };

  const handleSave = () => {
    if (currentItem) {
      currentItem.code = currentCode;
      currentItem.quantity = quantity;
      setCurrentItem(null);
    } else {
      setScannedBarcodes([...scannedBarcodes, { code: currentCode, quantity }]);
    }
    setShowModal(false);
    setCurrentCode('');
    setQuantity('1'); // Reset quantity for the next scan
  };

  const handleClose = () => {
    setShowModal(false);
    setScannerMode('BARCODE');
  }

  const toggleScannerMode = () => {
    scannerMode === 'BARCODE' ? setScannerMode('DESCRIPTION') : setScannerMode('BARCODE');
  }

  // @ts-ignore
  return (
    <Box component="div">
      {scannerMode === 'BARCODE' ? scanner : null}
      <Grid container>
        <Typography sx={{ width: '80%', alignSelf: 'left' }} variant="h6" component="div">
          Articles
        </Typography>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={(i) => setShowModal(true)}
        >
          <AddIcon color={"primary"} />
        </IconButton>
        <ArticleTable
          items={scannedBarcodes}
          removeItem={removeItem}
          editItem={editItem}
        />
        <Grid item>
          <Button
            variant="contained"
            onClick={() => setScannerOn(!scannerOn)}
          >
            Camera {scannerOn ? 'ON' : 'OFF'}
          </Button>
          <Button
            sx={{ alignSelf: 'flex-end'}}
            variant="contained"
            onClick={() => handelContinue()}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        open={showDialog}
        onClose={setShowDialog}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handelCancelSend()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Button size="medium" color="inherit" onClick={() => handelScanProject()}>
              Scanner
            </Button>
            <Button size="medium" color="inherit" onClick={() => handleSend()}>
              Skicka
            </Button>
          </Toolbar>
        </AppBar>
        <Box sx={{ alignSelf: 'center', justifyContent: 'center', width: '80%' }}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Describe and send picked items
          </Typography>
          {scannerMode === 'DESCRIPTION' ? (
            <Box component="span" sx={{ display: 'hidden' }}>
              {scanner}
            </Box>
          ) : null}
          <form>
            <TextField
              style={{ width: "100%", marginBottom: "1em" }}
              type="text"
              multiline
              minRows="4"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              label="Project"
              variant="outlined"
            />
            <br />
            <TextField
              style={{ width: "100%", marginBottom: "1em" }}
              type="text"
              label="Your name"
              onChange={(e) => setUser(e.target.value)}
              variant="outlined"
            />
          </form>
          <ArticleTable
            items={scannedBarcodes}
            removeItem={removeItem}
            editItem={editItem}
          />
        </Box>
      </Dialog>
      <Dialog
        fullScreen
        open={showModal}
        onClose={setShowModal}
        TransitionComponent={Transition}
      >
        <ArticleForm
          item={{ code: currentCode, quantity }}
          setCode={setCurrentCode}
          setQuantity={handelSetQuantity}
          close={handleClose}
          saveAndClose={handleSave}
        />
      </Dialog>
    </Box>
  );
};

export default BarcodeScanner;
