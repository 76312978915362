import React from 'react';
import './App.css';
import BarcodeScanner from "./BarcodeScanner";
import { ThemeProvider } from "@mui/styles";
import { createMuiTheme } from "@mui/material";

const theme = createMuiTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <header className="App-header">
          <meta name="viewport" content="initial-scale=1, width=device-width" />
          <BarcodeScanner />
        </header>
      </div>
    </ThemeProvider>
  );
}

export default App;
