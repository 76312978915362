import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Item } from "../lib/types";
import DeleteIcon from "@mui/icons-material/Delete";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { IconButton } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";

export interface TableProps {
  items: Item[];
  removeItem: (item: string) => void;
  editItem: (item: string) => void;
}

const tableStyle = makeStyles({
  table: {
    color: "white"
  }
});

const StyledTableCell = styled(TableCell)({
  color: 'aliceblue',
  backgroundColor: 'aliceblue',
  padding: 8,
  // borderRadius: 4,
});

// const StyledTableCell = withStyles({
//   root: {
//     color: "white"
//   }
// })(TableCell);

export default function ArticleTable(props: TableProps) {
  const { items, removeItem, editItem } = props;
  const classes = tableStyle();

  return (
    <TableContainer sx={{ color: 'white', width: '100%', }}>
      <Table className={classes.table} aria-label="article table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Article</StyledTableCell>
            <StyledTableCell align="right">Quantity</StyledTableCell>
            <StyledTableCell align="right">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow
              key={row.code}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCell align="left">
                {row.code}
              </StyledTableCell>
              <StyledTableCell align="right">{row.quantity}</StyledTableCell>
              <StyledTableCell align="right">
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(i) => editItem(row.code)}
                >
                  <EditRoundedIcon
                    color={"primary"}
                  />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(i) => removeItem(row.code)}
                >
                  <DeleteIcon
                    color={"primary"}
                  />
                </IconButton>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
